import React, { useState } from "react";
import styles from "../styles/privacy-policy.css";

const PrivacyPolicy = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="privacy-policy">
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <h2>Privacy Policy</h2>
        <h2>{expanded ? "-" : "+"}</h2>
      </div>
      {expanded ? (
        <>
          <p>
            <strong>
              Megashots Internet Private Limited (GetMega), a company registered
              under the Companies Act, 2013 and with its registered office at
              #25, 8th Main, 2nd Floor, Vasanthnagar, Bangalore, Karnataka 560
              052. (“We” or “Us” or “Our” or “Company”), owns and manages
            </strong>
          </p>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>
              <strong>the website </strong>
              <a href="http://www.getmega.com">
                <strong>www.getmega.com</strong>
              </a>
            </li>
            <li>
              <strong>
                the GetMega application (“App”), a skill-based gaming
                application; and
              </strong>
            </li>
            <li>
              <strong>
                such other applications, platforms and games which may be made
                available from time to time (together “Platform” or “Platforms”)
                through which You have opted to avail of certain Services by
                agreeing to the terms of use (“Terms of Use”), available on the
                home screen of the Platform.
              </strong>
            </li>
          </ol>
          <p>
            <strong>
              For the purposes of this Privacy Policy (defined below), unless
              defined hereunder, all capitalized terms shall have the meaning
              ascribed to them in the Terms of Use.
            </strong>
          </p>
          <p>
            <strong>Your privacy is very important to GetMega</strong>
          </p>
          <p>
            At GetMega, we believe in being absolutely transparent about our
            Privacy Policy so that you can focus on playing on the platform
            without worrying.
          </p>
          <p>
            We maintain complete confidentiality of all your personal
            information collected by us on our platform. We have very thorough
            methods to safeguard your data. This Privacy Policy forms a crucial
            part of the Terms of Use.
          </p>
          <p>
            This Privacy Policy is a statement of the GetMega’s policies and
            procedures for collection, use, storage, processing, disclosure and
            protection of any Personal Information which you may be required to
            give or make available on the Platforms in order to use the
            Platforms.
          </p>
          <p>
            This Privacy Policy applies to all current and former users
            accessing or using Our Platforms, or otherwise engaging with us
            through email or other means (collectively, “You” or “Your” or
            “Users”). We recommend that you go through this Privacy Policy
            carefully before accessing or using the Platform.
          </p>
          <p>
            <strong>
              Our goal is to offer a safe, reliable and secure environment to
              play.
            </strong>
          </p>
          <p>
            GetMega respects your privacy and thus assures you that any
            information provided by you to GetMega is protected and will be
            dealt with according to this Policy.
          </p>
          <p>
            GetMega shall not provide, distribute, trade, sell or rent your
            name, e-mail address or other personal information provided on this
            site to any third party or information related to your usage of the
            Website, except as provided in this Privacy Policy.
          </p>
          <p>
            By using the Service, you (“User” or “you”) agree to the terms of
            this Privacy Policy. We reserve the right to amend this Privacy
            Policy and changes will come into effect immediately upon such
            change being notified on the Sites. Your continued use of the
            Service constitutes your agreement to this Privacy Policy and any
            amendments to the same thereinafter. Our Terms of Use take
            precedence over any conflicting provision of this Privacy Policy.
            Unless otherwise defined herein, capitalised terms shall have the
            meaning given to them in our Terms of Use.
          </p>
          <p className="underlined">What information we collect</p>
          <ul>
            <li>
              To avail the facilities offered on{" "}
              <a href="http://www.GetMega.com">www.GetMega.com</a> or through
              the GetMega app, you will have to create a Login ID including your
              Username, Password and a Valid Email address.
            </li>
            <li>
              Additionally, for participating in any tournaments / games /
              competitions organized by GetMega where a pay-out is involved, you
              will need to submit your Name, Date of Birth, Telephone number,
              Postal/Contact address, bank account details, and PAN number. We
              may also collect information about Facebook account ID and sign-in
              information, Facebook profile photo, Facebook account ID of
              Facebook friends, Google account ID, PAN Number, picture of any of
              the government issued photo identity and address proof such as
              driving license, voter ID, Aadhaar card, passport and other
              associated details required for your KYC forms, PAN card, contact
              details from your mobile address book, and details of your
              WhatsApp profile (collectively, “Account Information”).
            </li>
            <li>
              In addition, you might voluntarily submit additional information
              including profile pictures, nick names, social media profiles,
              Aadhar number etc.
            </li>
            <li>
              When you visit www.GetMega, certain information may also be
              automatically collected and stored, including the IP address of
              your computer, browser type and language, the date and the time
              during which you accessed the site, UDID and MAC data,
              geo-location data, the address of the website which you may have
              used to link to GetMega.
            </li>
            <li>
              When you visit GetMega, cookies may be left in your computer. A
              cookie is a small text file that uniquely identifies your browser.
              The cookies assigned by the servers of GetMega may be used to
              personalize your experience on GetMega. Moreover, cookies may also
              be used for authentication, game management and security purposes.
            </li>
            <li>
              GetMega may also use software applications for website traffic
              analysis and to gather statistics used for advertising and for
              determining the efficacy and popularity of GetMega among others.
            </li>
            <li>
              Cookies may also be assigned by the advertisers of GetMega when
              you click on any of the advertisements which may be displayed on
              GetMega in which case such cookies are controlled by these
              advertisers and not GetMega.
            </li>
            <li>
              We record your playing history on GetMega including hands played,
              money deposited, wagered, won or withdrawn, game session
              durations, your high scores, your preferred game settings, and
              more broadly all actions taken within our software will be logged.
            </li>
            <li>
              We collect information about your use of our services, such as
              applications' usage, log files, user activity (e.g. pages viewed,
              the amount of time spent on particular pages, online browsing,
              clicks, actions, etc.), time stamps, alerts, etc. This information
              is collected for amongst other things troubleshooting errors and
              bugs as well as for research and analytics purposes about your use
              of the Services.
            </li>
            <li>
              We may collect Personal Information from third party service
              providers, such as information about your credit history from
              credit agencies and other financial information which is relevant
              to the provision of our services, as well as information which is
              gathered in order to verify your identity and prevent fraudulent
              or illegal activity.
            </li>
          </ul>
          <p className="underlined">
            How GetMega uses the collected information
          </p>
          <ul>
            <li>
              When you register your email address with GetMega, you agree to
              receive email communication from GetMega, entities specifically
              authorized by GetMega and other users. You also agree and
              acknowledge that when you use our referral program for referring
              someone, GetMega will send emails to that person on your behalf
              and the email headers will carry your email address as the address
              from which such emails are sent.
            </li>
            <li>
              GetMega has a policy of not sharing any personally identifiable
              information with anyone other than entities specifically
              authorized by GetMega which may include advertisers and sponsors
              of GetMega. However, GetMega may use your name, Login ID and the
              state from where you are participating when announcing the results
              of any contests run on the Website. Such contests are further
              governed by the Terms and Conditions which shall be available on
              the website as and when such a contest is run on the website.
            </li>
            <li>
              GetMega conducts periodic analysis and survey of the traffic to
              GetMega for market research and advertising purposes.
            </li>
            <li>
              GetMega reserves the right to share your registration information
              with GetMega appointed market research and advertising companies
              or firms from time to time for the said purposes.
            </li>
            <li>
              GetMega may also use cumulative non-personal information for
              auditing and analysis purposes with the aim of improving its
              services.
            </li>
            <li>
              Your playing history including hands played, money deposited or
              withdrawn, and other actions taken within our software can be
              analysed by us for the purpose of graphical displays, user
              feedback, analytics and marketing either by us or by associate
              companies engaged for this purpose.
            </li>
            <li>
              We may anonymize or de-identify the information collected by the
              services or via other means so that the information cannot, on its
              own, personally identify you. Our use and disclosure of such
              aggregated or de-identified information is not subject to any
              restrictions under this Privacy Policy, and we may disclose it to
              others without limitation and for any purpose, such as for
              advertising or marketing purposes.
            </li>
            <li>
              If we should ever file for bankruptcy, or become insolvent, or be
              acquired by a third party, merge with a third party, sell all or
              part of Our assets, or transfer substantially all or part of Our
              relevant assets to a third party, we are entitled to share all the
              information you have provided through the Services to our
              business, investment, and merger partners. The same would apply
              (under obligations of confidentiality / non-disclosure agreements)
              for potential business, investment, and merger partners.
            </li>
            <li>
              Additionally, GetMega may share personally identifiable
              information if sharing of such information is necessary to comply
              with legal processes or governmental request, or to enforce the
              Terms of Use and this Privacy Policy, or for prevention of fraud,
              or for issues involving information security, or to protect your
              rights or the rights of GetMega or the rights of the general
              public.
            </li>
          </ul>
          <p className="underlined">User's Responsibility</p>
          <ul>
            <li>
              When you register with GetMega, your account is protected by means
              of login information which includes a username and a password that
              is known only to you. Therefore, you are responsible for
              maintaining the confidentiality of your login information
              including your username and password.
            </li>
            <li>
              We suggest you do not disclose your password to anyone. If you
              become aware of or reasonably suspect any breach of security,
              including compromise of your login information, it is your
              responsibility to immediately notify GetMega.
            </li>
            <li>
              GetMega may contain links to other websites. Such websites are
              governed by their own privacy policies and GetMega does not
              exercise any control over them. It is your responsibility to read
              and understand the privacy policy of such websites when you follow
              a link outside GetMega. GetMega is not responsible for the privacy
              practices or the content of such websites.
            </li>
            <li>
              Our services are not intended for or directed at persons under the
              age of eighteen (18). For any person who informs us that his age
              is eighteen (18) years or above, we reserve the right to access
              and verify the person's Personal Information, but the
              responsibility for not submitting any incorrect personal data
              remains with the user.
            </li>
            <li>
              Data which is transmitted over the Internet is intrinsically
              exposed to security risks or threats. For instance, information
              transmitted via chat or email can be compromised and used by
              others. Therefore, GetMega cannot guarantee any security for such
              information.
            </li>
          </ul>
          <p className="underlined">Consent</p>
          <ul>
            <li>
              By using our website, it is understood that you have given your
              consent to the collection and use of the information listed in the
              “Information gathered” section by GetMega and its partners and
              affiliates.
            </li>
            <li>
              In order to play real money games, you will be required to send
              money to and receive money from us. We may use third-party
              electronic payment processors and/or financial institutions
              ('ESPs') to process such financial transactions.
            </li>
            <li>
              By accepting this Privacy Policy, you expressly consent to
              Personal Information necessary for the processing of transactions
              being shared with ESPs. We take steps to ensure that our
              arrangements with ESPs protect your privacy.
            </li>
            <li>
              Our services are not intended for or directed at persons under the
              age of eighteen (18). For any person who informs us that his age
              is eighteen (18) years or above, we reserve the right to access
              and verify the person's Personal Information. If we become aware
              that a minor has attempted to or has submitted personal
              information via the Services, we will not accept this information
              and will take steps to remove such information from our records in
              a secure manner.
            </li>
            <li>
              We reserve the right to conduct a security review at any time to
              validate your identity, age, the registration data provided by you
              and to verify your use of the services and your financial
              transactions for potential breach of our Terms and Conditions of
              Use and of applicable law. By using our website, you authorize us,
              our staff, agents and suppliers to use your Personal Information
              and to disclose your Personal Information to third parties for the
              purposes of validating the information you provide to us.
            </li>
            <li>
              By filling your mobile number on our site, you are giving
              Megashots Internet Private Limited and its affiliates the right to
              contact you by Call/SMS to provide you information on all
              promotional activity/events conducted by GetMega and its partners.
              Your mobile number will not be used for any third-party
              communication whatsoever.
            </li>
            <li>
              Our software package contains certain features designed to detect
              use of automated programs that enable artificial (non-human)
              intelligence to play on our site. Use of such 'bot' software
              programs violates Our Terms and Conditions of Use. By installing
              Our software and using the Services, you consent to our software
              performing any or all of the following functions:
            </li>
            <li>
              Scan your list of active software applications while you are using
              the services;
            </li>
            <li>
              Scan your list of active processes while you are using the
              services; and
            </li>
            <li>
              Scan the files in your services and site-related program folders
              to ensure that only 'non-hacked' versions of our software are
              being used.
            </li>
            <li>
              If any of the foregoing processes reveals a suspect application or
              process, our software may scan the files associated with the
              suspect application or process and compile a composite mesh (i.e.,
              a profile that characterizes the files associated with the
              application or process) to be matched against profiles for known
              illicit automated programs.
            </li>
            <li>
              Our software will not perform any random search of large portions
              of Your hard drive, equipment or files, and it will not transmit
              any other information to us or to any third party other than as
              described here.
            </li>
            <li>
              You consent to our sharing your information with any third parties
              who organize offline events or tournaments on behalf of or in
              conjunction with GetMega.
            </li>
            <li>
              You consent to sharing information with GetMega’s Auditors,
              contractors or business process advisors
            </li>
          </ul>
          <p className="underlined">Data Retention</p>
          <p>
            Your personal information may be retained and may continue to be
            used until: (i) the relevant purposes for the use of your
            information described in this Privacy Policy are no longer
            applicable; and (ii) we are no longer required by applicable law,
            regulations, contractual obligations or legitimate business purposes
            to retain your personal information and the retention of your
            personal information is not required for the establishment, exercise
            or defense of any legal claim.
          </p>
          <p className="underlined">Applicable Law and Jurisdiction</p>
          <p>
            By visiting the GetMega platform, you agree that the laws of the
            Republic of India without regard to its conflict of laws principles,
            govern this Privacy Policy and any dispute arising in respect hereof
            shall be subject to and governed by the dispute resolution process
            set out in the Terms and Conditions.
          </p>
          <p className="underlined">
            Updates or Amendments to the Privacy Policy
          </p>
          <ul>
            <li>
              We may revise this Privacy Policy from time to time, including
              without limitation as a result of legal and regulatory changes,
              security reasons and changes to our services, and the most current
              version will always be posted on this website. We encourage you to
              review this Privacy Policy regularly for any changes.
            </li>
            <li>
              Your continued use of our services, following the notification of
              such amendments on this website, constitutes your acknowledgement
              and consent of such amendments to the Privacy Policy and your
              agreement to be bound by the terms of such amendments.
            </li>
          </ul>
          <p className="underlined">Grievances</p>
          <p>
            In accordance with the IT Act and rules made thereunder, the name
            and contact details of the Grievance Officer are provided below:
          </p>
          <p style={{ marginBottom: 0 }}>Name: Rajesh Menon</p>
          <p>Email: rajesh@getmega.com</p>
          <p>
            For any questions, feedback or complaints in relation to any matter
            concerning this Privacy Policy, You may contact the above designated
            Grievance Officer.
          </p>
        </>
      ) : null}
    </div>
  );
};

export default PrivacyPolicy;
