import React, { useState } from "react";
import "../styles/privacy-policy.css";

const ResponsibleGaming = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className="privacy-policy"
      style={{ paddingBottom: "80px", marginTop: 0 }}
    >
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <h2>Responsible Gaming</h2>
        <h2>{expanded ? "-" : "+"}</h2>
      </div>

      {expanded ? (
        <>
          <p>
            At GetMega we want you to enjoy our platform and multiple games
            responsibly. We strive to assist and protect players who are at risk
            of the potential negative effects of gaming through a number of
            means, including player age verification and guidance on responsible
            gaming.
          </p>

          <p className="underlined">Age verification</p>
          <p>
            Every person signing up for a new account must check a box that
            indicates that they are at least 18 years of age. This notifies
            everybody that we don’t accept players under 18.
          </p>
          <p>
            When a player creates an account with us, we collect their name,
            address, and birth date to confirm that the player is at least
            18-years-old.
          </p>
          <p>
            Any underage player who has provided dishonest or inaccurate
            information regarding their true age may have all winnings forfeited
            and could face criminal prosecution.
          </p>
          <p>
            We do not target underage players with our marketing and
            advertising.
          </p>
          <p>
            We are a member of the All India Gaming Federation ("AIGF"), with
            the purpose of self-regulating all Online Games of Skill within the
            territory of the Republic of India. The AIGF Charter can be found
            here.
          </p>

          <p className="underlined">What you can do to help yourself</p>
          <p>
            If you choose to play online, there are some general guidelines that
            can help make your playing experience safer, and reduce the risk of
            problems occurring:
          </p>
          <ol>
            <li>Play for entertainment, and not as a way of making money.</li>
            <li>Make frequent withdrawals when you win.</li>
            <li>
              Play with money that you can afford to lose. Never use money that
              youneed for you necessities like rent, tuition, food, tuition etc.
            </li>
            <li>
              Set deposit limits and ensure you never deposit more than you can
              afford to lose.
            </li>
            <li>
              Do not try to compensate for your losses by playing higher stakes.
            </li>
            <li>
              Don't play when you are upset, tired, or depressed. It is
              difficult to make good decisions when you are feeling down.
            </li>
            <li>
              Balance your playing with other activities. Find other forms of
              entertainment so playing does not become too big a part of your
              life.
            </li>
          </ol>

          <p className="underlined" style={{ marginBottom: 0 }}>
            Contact Us:
          </p>
          <a href="mailto:help@getmega.com">help@getmega.com</a>
        </>
      ) : null}
    </div>
  );
};

export default ResponsibleGaming;
