import React from "react";
import { StaticQuery, graphql } from "gatsby";
// import Img from "gatsby-image";
import stars from "../images/star.svg";
import icon from "../images/icon.svg";
// import Features from './Features';
import Carousel2 from "./Carousel2";
import Description from "./Description";
import { generateUrl } from "./utils/generateUrl";
import { defaultInstallUrl } from "./utils/installUrl";
import FreeGemsPopup from "./FreeGemsPopup";
import GMLogo from "../images/gm_logo.png";

import "../styles/shiny.css";
import "../styles/App.css";

const Images = graphql`
  query {
    imageThumbnail: file(relativePath: { eq: "image.png" }) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    play: file(relativePath: { eq: "play.png" }) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    f1: file(relativePath: { eq: "great mobile experience.png" }) {
      childImageSharp {
        fluid(maxWidth: 78) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    f2: file(relativePath: { eq: "multiple games.png" }) {
      childImageSharp {
        fluid(maxWidth: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    f3: file(relativePath: { eq: "great customer support.png" }) {
      childImageSharp {
        fluid(maxWidth: 72) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    f4: file(relativePath: { eq: "smooth deposits.png" }) {
      childImageSharp {
        fluid(maxWidth: 76) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    f5: file(relativePath: { eq: "verified profiles.png" }) {
      childImageSharp {
        fluid(maxWidth: 88) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    f6: file(relativePath: { eq: "legal.png" }) {
      childImageSharp {
        fluid(maxWidth: 76) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    partners: file(relativePath: { eq: "payment partners.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

class App extends React.Component {
  state = {
    initialMount: true,
    featuresVisible: false,
    progress: 0,
    installUrl: defaultInstallUrl,
    isFreeGemsPopupOpen: false,
  };

  eventTracker = (eventSource) => {
    console.log("fbevent:", eventSource);
    // window._loq.push(["tag", "Download Click", true]);
    window.fbq("trackCustom", "Login");
    window.gtag("event", "Download APK", {
      event_category: "Downloaded",
      event_label: eventSource,
      value: 1,
    });
    // this.tiktokPixelConversion();
    // this.gtag_report_conversion();
    console.log("firing ob pixel");
    window.obApi("track", "Download");
  };

  // tiktokPixelConversion() {
  //   console.log("running tik tok pixel conversion code");
  //   //old pixel
  //   var ta1 = document.createElement("script");
  //   ta1.type = "text/javascript";
  //   ta1.async = true;
  //   ta1.src =
  //     document.location.protocol +
  //     "//" +
  //     "static.bytedance.com/pixel/sdk.js?sdkid=BMNSLLKB5B70QD216IF0";
  //   var s1 = document.getElementsByTagName("head")[0];
  //   s1.appendChild(ta1);
  //   //new pixel
  //   var ta = document.createElement("script");
  //   ta.type = "text/javascript";
  //   ta.async = true;
  //   ta.src =
  //     document.location.protocol +
  //     "//" +
  //     "static.bytedance.com/pixel/sdk.js?sdkid=BN93K3C0M0F0PKIEFCO0";
  //   var s = document.getElementsByTagName("head")[0];
  //   s.appendChild(ta);
  // }

  // gtag_report_conversion(url) {
  //   var callback = function() {
  //     if (typeof url != "undefined") {
  //       window.location = url;
  //     }
  //   };
  //   window.gtag("event", "conversion", {
  //     send_to: "AW-718511341/bObTCLPJlKgBEO25ztYC",
  //     event_callback: callback,
  //   });
  //   return false;
  // }

  componentDidMount() {
    // generate onelink url
    let url = generateUrl();
    if (url && url !== " ") this.setState({ installUrl: url });

    let self = this;
    window.onscroll = function() {
      console.log(window.pageYOffset, self.state.initialMount, "--");
      if (self.state.initialMount && window.pageYOffset > 2) {
        console.log("entered+++++++++");
        self.setState({ featuresVisible: true, initialMount: false }, () => {
          console.log("state is set");
        });
      }
    };

    // open free gems popup after 30 seconds
    console.log("starting timer");
    const freeGemsTimer = setTimeout(() => {
      this.setState({ isFreeGemsPopupOpen: true });
    }, 30 * 1000);
    this.setState({ freeGemsTimer });
  }

  componentWillUnmount() {
    clearTimeout(this.state.freeGemsTimer);
  }

  closeFreeGemsPopup = () => {
    this.setState({ isFreeGemsPopupOpen: false });
  };

  render() {
    let { featuresVisible, installUrl, isFreeGemsPopupOpen } = this.state;

    return (
      <>
        <StaticQuery
          query={Images}
          render={(data) => {
            console.log(data);
            return (
              <div className="app-container">
                <div className="main-screen">
                  <div className="logo">
                    <img src={GMLogo} alt="getmega logo" />{" "}
                  </div>
                  <div className="image-container">
                    {/* <Img fluid={data.imageThumbnail.childImageSharp.fluid} alt="" /> */}
                    <Carousel2 />
                  </div>
                  <div className="legend">
                    <div className="stats">
                      <div className="rating">
                        <div
                          className="rating-num font-header align-row-center marBot5"
                          style={{
                            fontFamily: "'Gotham', sans-serif !important",
                          }}
                        >
                          4.7
                        </div>
                        <div className="rating-star">
                          <img src={stars} alt=" " />
                        </div>
                        <div
                          className="rating-label marneg5 font-text align-row-center"
                          style={{
                            fontFamily: "'Gotham', sans-serif !important",
                          }}
                        >
                          Ratings
                        </div>
                      </div>
                      <div className="players">
                        <div
                          className="players-num font-header marBot5 align-row-center"
                          style={{
                            fontFamily: "'Gotham', sans-serif !important",
                          }}
                        >
                          3.5
                        </div>
                        <div
                          className="players-lakh font-text align-row-center height16 "
                          style={{
                            fontFamily: "'Gotham', sans-serif !important",
                          }}
                        >
                          Lakh
                        </div>
                        <div
                          className="players-players font-text mar"
                          style={{
                            fontFamily: "'Gotham', sans-serif !important",
                          }}
                        >
                          Players
                        </div>
                      </div>
                      <div className="prizes">
                        <div
                          className="prizes-numb font-header marBot5 align-row-center"
                          style={{
                            fontFamily: "'Gotham', sans-serif !important",
                          }}
                        >
                          20
                        </div>
                        <div
                          className="prizes-text1 font-text height16"
                          style={{
                            fontFamily: "'Gotham', sans-serif !important",
                          }}
                        >
                          Lakh+ Prizes
                        </div>
                        <div
                          className="prizes-text2 font-text mar1 align-row-center"
                          style={{
                            fontFamily: "'Gotham', sans-serif !important",
                          }}
                        >
                          Distributed
                        </div>
                      </div>
                    </div>
                    <div id="glossy-button" className="download">
                      <a
                        onClick={() => this.eventTracker("Download app Button")}
                        href={installUrl}
                      >
                        <div className="icon-flex">
                          <img src={icon} alt="" />
                        </div>
                        <div
                          className="download-text font-download"
                          style={{
                            fontFamily: "'Gotham', sans-serif !important",
                          }}
                        >
                          Download App
                        </div>
                      </a>
                      <i></i>
                    </div>
                    {/* <div className="download-image">
                        <a href="https://getmega.com/latest">
                        <Img fluid={data.play.childImageSharp.fluid} alt="" /> 
                        </a>
                        </div> */}
                  </div>
                </div>
                <Description
                  data={data}
                  eventTracker={this.eventTracker}
                  reviewsVisible={featuresVisible}
                  installUrl={installUrl}
                />
              </div>
            );
          }}
        />
        {isFreeGemsPopupOpen && (
          <FreeGemsPopup
            closeFreeGemsPopup={this.closeFreeGemsPopup}
            installUrl={installUrl}
            fbEventAndShowModal={this.eventTracker}
          />
        )}
      </>
    );
  }
}

export default App;
